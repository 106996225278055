<template>
  <section id="workflow-users-list">
    <div class="vue-data-table-default p-4">
      <data-tables-server
        :data="data"
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :pagination-props="paginationProps"
        @query-change="loadData"
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        :element-loading-text="loadingText"
      >
        <el-table-column
          v-for="column in columns"
          :key="column.key"
          :label="column.label"
          :prop="column.key"
          :width="200"
        >
          <template #default="{ row }">
            <div v-if="column.key === 'picture'">
              <img
                :src="row[column.key]"
                alt="Image"
                style="
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  border-radius: 8px;
                "
              />
            </div>
            <div v-else-if="column.type === 'file'">
              <el-button
                type="primary"
                @click="handleDownload(row, column)"
                size="mini"
              >
                <i class="el-icon-download"></i>
              </el-button>
              <el-button
                type="info"
                @click="handleView(row, column)"
                size="mini"
              >
                <i class="el-icon-view"></i>
              </el-button>
            </div>

            <div v-else-if="column.key === 'commentary'">
              <span v-if="!row.showFullCaption">
                {{
                  processCommentary(row[column.key])
                    .split(" ")
                    .slice(0, 10)
                    .join(" ")
                }}...
                <el-button
                  type="text"
                  @click="toggleCaption(row)"
                  style="color: #409eff"
                >
                  See More
                </el-button>
              </span>
              <span v-else>
                <span v-html="processCommentary(row[column.key])"></span>
                <el-button
                  type="text"
                  @click="toggleCaption(row)"
                  style="color: #409eff"
                >
                  See Less
                </el-button>
              </span>
            </div>

            <div v-else-if="row[column.key] && isContentLong(row[column.key])">
              <div>
                <span v-if="!row.showFullCaption">
                  {{ row[column.key].split(" ").slice(0, 10).join(" ") }}...
                  <el-button
                    type="text"
                    @click="toggleCaption(row)"
                    style="color: #409eff"
                  >
                    See More
                  </el-button>
                </span>
                <span v-else>
                  {{ row[column.key] }}
                  <el-button
                    type="text"
                    @click="toggleCaption(row)"
                    style="color: #409eff"
                  >
                    See Less
                  </el-button>
                </span>
              </div>
            </div>

            <div v-else-if="column.key === 'comments'">
              <span>
                {{ getCommentText(row) }}
              </span>
            </div>
            <div v-else>
              {{ row[column.key] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          key="actions"
          label="Actions"
          prop="actions"
          :width="200"
        >
          <template #default="{ row }">
            <el-button type="primary" size="mini" @click="viewRecord(row)">
              <i class="el-icon-view"></i>
            </el-button>
            <el-button type="primary" size="mini" @click="editRecord(row)">
              <i class="el-icon-edit"></i> </el-button
          ></template>
        </el-table-column>
      </data-tables-server>
    </div>
    <dialog-component
      title="Edit"
      :visible="isEditDialogVisible"
      @before-close="isEditDialogVisible = false"
      ><editIntegrationData
        :fields="columns"
        :row="selectedRecord"
        :component="component"
        :integrationDetails="integrationDetails"
      ></editIntegrationData>
      <template #footer>
        <el-button type="primary" @click="updateRecord" size="small">
          <i class="el-icon-loading" v-if="updateLoading"></i>
          {{ updateLoading ? `${updateLoadingText}` : "update" }}</el-button
        >
      </template>
    </dialog-component>
    <dialog-component
      title="View"
      :containerWidth="'40%'"
      :width="getIsMobile ? '100%' : '50%'"
      :visible="isViewDialogVisible"
      @before-close="isViewDialogVisible = false"
      ><viewIntegrationData
        :fields="columns"
        :row="selectedRecord"
      ></viewIntegrationData>
      <template #footer>
        <el-button type="primary" @click="goToEdit" size="small">
          <i class="el-icon-edit"></i> Edit</el-button
        >
      </template>
    </dialog-component>
    <div v-if="fileLoading" class="loading-container">
      <div class="loading-content">
        <img src="@/assets/downloading.gif" alt="docs" />
        <p>{{ fileLoadingText }}</p>
      </div>
    </div>
    <div v-if="fileViewLoading" class="loading-container">
      <div class="loading-content">
        <img src="@/assets/fileView.gif" alt="docs" />
        <p>{{ fileLoadingText }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { postAPICall } from "@/helpers/httpHelper";
import viewIntegrationData from "./viewIntegrationData.vue";
import editIntegrationData from "./editIntegrationData.vue";
import { mapGetters } from "vuex";
export default {
  components: { viewIntegrationData, editIntegrationData },
  name: "IntegrationTable",
  props: {
    component: Object,
    integrations: Array,
    predefinedIntegrations: Array,
    searchString: String,
  },
  mixins: [],
  data() {
    return {
      localSearchString: this.searchString,
      total: 0,
      rows: [],
      currentPage: 1,
      pageSize: 0,
      data: [],
      loading: false,
      loadingText: "Loading...",
      columns: [],
      availableColumns: [],
      selectedFields: [],
      isSettingsDialogVisible: false, //
      isEditDialogVisible: false,
      isViewDialogVisible: false,
      showTable: false,
      fileLoading: false,
      fileViewLoading: false,
      fileLoadingText: null,
      selectedRecord: null,
      updateLoading: false,
      updateLoadingText: "",
    };
  },
  watch: {
    async searchString(newVal) {
      this.localSearchString = newVal;
      await this.loadData();
    },
  },
  computed: {
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    ...mapGetters("navigationOpen", ["getIsMobile"]),
},
  async mounted() {
    let limit = (this.pageSizes && this.pageSizes[0]) || 10;
    this.pageSize = limit;
    await this.getNecessaryInfo();
  },
  methods: {
    processCommentary(commentary) {
      if (!commentary) return "";
      const regex = /\{hashtag\|\\#\|([^}]+)\}/g;
      const formattedHashtags = [];
      const nonHashtagText = commentary.replace(regex, "").trim();
      let match;
      while ((match = regex.exec(commentary)) !== null) {
        formattedHashtags.push(`#${match[1]}`);
      }
      const resultText =
        nonHashtagText +
        (formattedHashtags.length > 0
          ? "\n" + formattedHashtags.join(" ")
          : "");
      return resultText;
    },
    isContentLong(content) {
      return content && content.length > 100;
    },
    toggleCaption(row) {
      this.$set(row, "showFullCaption", !row.showFullCaption);
    },
    getCommentText(row) {
      if (row.comments && Array.isArray(row.comments.data)) {
        return row.comments.data.map((comment) => comment.text).join(", ");
      }
      return "";
    },
    async loadData() {
      if (this.pageSize != 0) {
        await this.getNecessaryInfo();
      }
    },
    async getNecessaryInfo() {
      if (this.component && this.component.apply_date_filter) {
        if (!this.component.dates.startDate || !this.component.dates.endDate) {
          this.$notify.error({
            title: "Error",
            message: "Please Select date range",
          });
          return;
        }
      }
      let integrationSettings = this.component.integrationsSettings;
      let dates = this.component.dates;
      let { integration_id, event, dependencies, selectedFields } =
        integrationSettings;
      let matchedIntegration = this.integrations.find(
        (id) => id._id === integration_id
      );
      let payload = {
        asset: event.asset,
        provider: matchedIntegration.application,
        connectionId: matchedIntegration.connection_id,
        dependencies: { ...dependencies, ...matchedIntegration.dependencies },
        dates,
        search: this.localSearchString,
        fields: selectedFields,
        pagination: {
          page: this.currentPage,
          pageSize: this.pageSize,
        },
      };
      let data = await this.fetchAllData(payload);
      this.total = data.metadata.total_records[event.eventKey] || 0;
      this.data = data.rows || [];
      this.columns = Object.keys(this.data[0]).map((key) => ({
        key,
        label: key.charAt(0).toUpperCase() + key.slice(1),
      }));
    },
    async fetchAllData(payload) {
      this.loading = true;
      try {
        const response = await postAPICall(
          "POST",
          "integrations/service/fetch-data",
          payload
        );
        this.loading = false;
        if (response.data) {
          return response.data;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        this.loading = false;
      }
    },
    async handleDownload(row, column) {
      try {
        this.fileLoadingText = "Please wait! Your file is downloading...";
        this.fileLoading = true;
        let response = await this.downloadMethod(row, column);
        this.fileLoading = false;
        let fileURL = response.data.url;
        if (response.data.type == "url") {
          let blobResponse = await fetch(fileURL, {
            method: "GET",
            responseType: "blob",
          });
          const blob = await blobResponse.blob();
          fileURL = window.URL.createObjectURL(blob);
        }
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = response.data.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        this.fileLoading = false;
        this.loading = false;
      }
    },
    async handleView(row, column) {
      try {
        this.fileLoadingText = "Please wait! Your file is rendering...";
        this.fileViewLoading = true;
        let response = await this.downloadMethod(row, column);
        this.fileViewLoading = false;
        let fileUrl = response.data.url;
        let fileExtension = response.data.extension;
        if (fileExtension === ".pdf") {
          let newTab = window.open();
          newTab.document.body.innerHTML = `<embed src="${fileUrl}" type="application/pdf" width="100%" height="100%"></embed>`;
        } else if (
          [".jpg", ".jpeg", ".png", ".gif", ".bmp"].includes(fileExtension)
        ) {
          let newTab = window.open();
          newTab.document.body.innerHTML = `<img src="${fileUrl}" width="100%" height="100%" />`;
        } else if ([".mp4", ".webm", ".ogg"].includes(fileExtension)) {
          let newTab = window.open();
          newTab.document.body.innerHTML = `<video src="${fileUrl}" width="100%" height="100%" controls></video>`;
        } else if ([".mp3", ".wav", ".ogg"].includes(fileExtension)) {
          let newTab = window.open();
          newTab.document.body.innerHTML = `<audio src="${fileUrl}" controls></audio>`;
        } else if ([".html", ".txt", ".csv"].includes(fileExtension)) {
          let newTab = window.open();
          newTab.document.body.innerHTML = `<iframe src="${fileUrl}" width="100%" height="100%"></iframe>`;
        } else {
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = response.data.fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        this.loading = false;
        this.this.fileViewLoading = false;
      }
    },
    async downloadMethod(row, column) {
      try {
        let connection_id =
          this.integrationDetails?.matchedApplication?.integration
            ?.connection_id || "";
        let requiredKeys = {};
        column.inputFields.forEach((key) => {
          requiredKeys[`${key}`] = row[key];
        });
        let payload = {
          provider: this.integrationDetails.matchedApplication.key,
          asset: this.integrationDetails.matchingEvents.asset,
          connectionId: connection_id,
          requiredKeys: requiredKeys,
        };
        let response = await postAPICall(
          "POST",
          "integrations/service/download-file",
          payload
        );
        return response;
      } catch (err) {
        return err;
      }
    },
    editRecord(row) {
      this.selectedRecord = row;
      this.isEditDialogVisible = true;
    },
    viewRecord(row) {
      const processedRow = { ...row };
      if (processedRow.commentary) {
        processedRow.commentary = this.processCommentary(
          processedRow.commentary
        );
      }
      this.selectedRecord = processedRow;
      this.isViewDialogVisible = true;
    },

    goToEdit() {
      this.isViewDialogVisible = false;
      this.isEditDialogVisible = true;
    },
    async updateRecord() {
      try {
        let payload = {
          provider: this.integrationDetails.matchedApplication.key,
          connectionId:
            this.integrationDetails.matchedApplication.integration
              .connection_id,
          asset: this.component.asset,
          parameters: this.component.dependencies,
          data: this.selectedRecord,
        };
        this.updateLoading = true;
        this.updateLoadingText = "updating...";
        let response = await postAPICall(
          "POST",
          "integrations/service/update-record",
          payload
        );
        this.updateLoading = false;
        if (response.success) {
          this.$notify.success({
            title: "Success",
            message: "Record updated successfully",
          });
          this.isEditDialogVisible = false;
        }
      } catch (err) {
        return err;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.approver-text {
  color: #3366cc;
}
.list-style {
  margin-top: 10px;
}
.expired-div {
  color: #dc3545;
}
.dull-text {
  color: #888;
  opacity: 0.8;
  overflow-wrap: break-word;
  word-break: normal;
}
.log-expired {
  color: #dc3545;
}
.resend-form-style {
  width: 70px;
  height: 25px;
}
.loading-container {
  position: fixed; /* Fixed positioning to cover the entire page */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: Add a semi-transparent background */
  z-index: 9999; /* Ensure it is on top of other elements */
}

.loading-content {
  text-align: center;
  color: white;
}
</style>
