<template>
  <el-row :gutter="20" class="record-view-container">
    <el-col v-for="(field, index) in fields" :key="index" :span="12">
      <div class="field-block">
      <span class="label">{{ field.label }}</span
      ><br />
      <span class="value">{{ row[field.key] }}</span>
    </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "viewRecord",
  props: {
    fields: {
      type: Array,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
/* Label Style */
.label {
  color: #677788;
  font-size: 16px; /* Adjust font size for label */
  line-height: medium; /* Line height for better spacing */
}

/* Value Style */
.value {
  color:#606266;
  font-size: 13px; /* Adjust font size for value */
  line-height: medium; /* Line height for better spacing */
}
.record-view-container {
  max-height: 60vh;
  overflow-y: auto;
  padding: 10px;
}
.field-block {
  padding: 10px;
  margin-bottom: 10px;
}
</style>
